import React, { useContext } from 'react';
import Footer from './components/layout/Footer';
import { motion } from 'framer-motion';
import Header from './components/layout/Header';
import { ThemeContext } from './context/ThemeContext'; // Import ThemeContext

const Layout = ({ children }) => {
    const { theme, themes } = useContext(ThemeContext); // Use theme from context

    return (
        <div
            className={`min-h-screen flex flex-col justify-between font-sans ${themes[theme].text}`}
            style={{ background: `${themes[theme].background}` }} // Dynamic background
        >
            <Header />
            <main className="flex-grow container mx-auto p-4">
                {children}
            </main>
            <motion.div
                id='LearningDSAFooter'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 5 * 1 }} // Delay based on number of socials
            >
                <Footer className="w-full" />
            </motion.div>
        </div >
    );
};

export default Layout;
