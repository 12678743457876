import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Home from './pages/Home';
import DailyRoutine from './pages/DailyRoutine';
import NotFound from './pages/NotFound'
import { ThemeProvider } from './context/ThemeContext'; // Import ThemeProvider

function App() {
  return (
    <ThemeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout><Home /></Layout>} />
          <Route path="*" element={<Layout><NotFound /></Layout>} />
          <Route path="/daily-routine" element={<DailyRoutine />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
