import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from '../../context/ThemeContext'; // Import ThemeContext
import SideMenu from './header/SideMenu';
import ThemeSwitcher from './header/ThemeSwitcher';
import PageLinks from './header/PageLinks';
import HamburgerMenu from './header/HamburgerMenu';
import CodesiosLogo from './header/CodesiosLogo';

const Header = () => {
    const { theme, changeTheme, themes, particleOptions } = useContext(ThemeContext); // Use theme from context
    const [menuOpen, setMenuOpen] = useState(false); // State to manage side menu

    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [menuOpen]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setMenuOpen(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <header
            id='LearningDSAHeader'
            className={`${themes[theme].background} ${themes[theme].text} p-4`}
            style={{ borderBottom: `2px solid ${themes[theme].borderColor}` }} // Dynamic bottom divider
        >
            <nav className="container mx-auto flex justify-between items-center relative">
                {/* Left-aligned element */}
                <CodesiosLogo />

                {/* Center-aligned element */}
                <div className="absolute left-1/2 transform -translate-x-1/2">
                    <PageLinks />
                </div>

                {/* Right-aligned elements */}
                <div className="flex space-x-4 items-center">
                    <HamburgerMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                    <ThemeSwitcher
                        themes={themes}
                        theme={theme}
                        changeTheme={changeTheme}
                        particleOptions={particleOptions}
                    />
                </div>


                <SideMenu
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    themes={themes}
                    changeTheme={changeTheme}
                    particleOptions={particleOptions}
                />
            </nav>
        </header>
    );
};

export default Header;
