import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Lottie from 'react-lottie-player';  // Import Lottie Player

const LottieAnimation = ({ spinnerAnimationLottie }) => {
    const [remainingHeight, setRemainingHeight] = useState(0);

    useEffect(() => {
        // Calculate the remaining height dynamically based on LearningDSA and its header
        const calculateRemainingHeight = () => {
            const viewportHeight = window.innerHeight;

            // Get the elements by their IDs
            const learningDSAHeaderElement = document.getElementById('LearningDSAHeader');
            const learningDSAElement = document.getElementById('LearningDSA');
            const learningDSAFooterElement = document.getElementById('LearningDSAFooter');

            // Get the heights (if the elements exist)
            const learningDSAHeaderHeight = learningDSAHeaderElement ? learningDSAHeaderElement.offsetHeight : 0;
            const learningDSAHeaderHeight2 = learningDSAHeaderElement ? learningDSAHeaderElement.offsetHeight : 0;
            const learningDSAHeaderHeight3 = learningDSAHeaderElement ? learningDSAHeaderElement.offsetHeight : 0;
            const learningDSAOffsetTop = learningDSAElement ? learningDSAElement.offsetTop : 0;
            const learningDSAHeight = learningDSAElement ? learningDSAElement.offsetHeight : 0;
            const learningDSAFooterHeight = learningDSAFooterElement ? learningDSAFooterElement.offsetHeight : 0;

            // Calculate the remaining height from the bottom of the LearningDSA element to the bottom of the viewport
            const remainingHeight = viewportHeight - (learningDSAHeaderHeight3 + learningDSAHeaderHeight2 + learningDSAHeaderHeight + learningDSAOffsetTop + learningDSAHeight + learningDSAFooterHeight);

            // Update the state with the calculated remaining height
            setRemainingHeight(remainingHeight);
        };

        // Initial calculation
        calculateRemainingHeight();

        // Recalculate on window resize
        window.addEventListener('resize', calculateRemainingHeight);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', calculateRemainingHeight);
        };
    }, []);



    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 4 * 1 }} // Delay based on number of socials
            style={{
                width: '100%',
                height: `${Math.max(remainingHeight, 220)}px`,  // Set calculated height
                overflow: 'hidden',              // Prevent scrollbar
                marginTop: '30px'
            }}
        >
            <Lottie
                loop
                animationData={spinnerAnimationLottie}
                play
                style={{ width: '100%', height: '100%' }}  // Set the size dynamically
            />
        </motion.div>
    );
};

export default LottieAnimation;
